import React from 'react';

import { SimilarPosts } from '../components/SimilarPosts.component';

import s from './VideoPost.module.scss';
import {htmlToReactExcerpt} from '@helpers/html-to-react-excerpt';
import {AlgoliaMini} from '../../../shared/components/algolia-mini/AlgoliaMini.component';
import {PostShare} from '../../../shared/components/post-share/PostShare.component';
import {PostContainer} from '../../../shared/components/post-container/PostContainer.component';
import {PostHeader} from '../../../shared/components/post-header/PostHeader.component';
import {AllWpPostsWithSlug, IWpVideoPost, WpBanner} from '../../../../types/wordpress.types';
import {Banner} from '../../banner/Banner.component';
import {useIntl} from 'gatsby-plugin-intl';
import {getLink} from '@helpers/linking.helper';
import {PageType} from '@constants/pageType.constants';
import multilingualSlugs from '../../../../intl/slugs.json';
import {Breadcrumbs} from '../../../shared/components/breadcrumbs/Breadcrumbs.component';
import { StoreSearch } from '@components/store-search/StoreSearch.component';


interface IVideoPost {
  video: IWpVideoPost;
  categoriesPosts: AllWpPostsWithSlug;
  banner: WpBanner;
  isTopBarVisible: boolean;
}

const VideoPost = ({video, banner, categoriesPosts}: IVideoPost) => {

  const intl = useIntl();
  
  return (
    <>
      <AlgoliaMini isTopBarVisible={true}/>
      <PostContainer post={video}>
        <Breadcrumbs areCrumbsCentered crumbs={[
          {
            name: intl.formatMessage({id: 'common__knowledgeBase'}),
            path: getLink(intl.locale, PageType.KNOWLEDGE_BASE)
          },
          {
            name: video.categories.nodes[0].slug,
            path: getLink(intl.locale,
              PageType.KNOWLEDGE_BASE) + multilingualSlugs[intl.locale][PageType.CATEGORY] + '/' + video.categories.nodes[0].slug
          },
          {
            name: video.title,
            path: ''
          },
        ]}/>
        <PostHeader>{video.title}</PostHeader>
        {htmlToReactExcerpt(video.excerpt)}
        <div className={s.videoPost__video}>
          <iframe
            className={s.videoPost__iframe}
            src={`https://youtube.com/embed/${video.videoContent.videoYoutube}?playlist=${video.videoContent.videoYoutube}`}>
          </iframe>
        </div>
        <PostShare author={video.videoContent.author ? video.videoContent.author[0] : null}
                   publishDate={video.date}/>
        <Banner
          banner={banner}
        />
      </PostContainer>
      <SimilarPosts categoriesPosts={categoriesPosts} />
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export default VideoPost;
