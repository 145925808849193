import React from 'react';
import { graphql } from 'gatsby';
import VideoPost from '../../app/knowledge-base/+post/+video-post/VideoPost.component';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { PageType } from '@constants/pageType.constants';
import { ThumbnailsHelper } from '@helpers/thumbnails.helper';
import { coversDictionary } from '@helpers/covers.helper';
import { useIntl } from 'gatsby-plugin-intl';
import { useLocalizedDynamicRoute } from '../../hooks/use-localized-dynamic-route';
import { useSiteMetadata } from '../../hooks/use-static-metadata';
import { getLink } from '@helpers/linking.helper';
import { getLocalizedSlug } from '@helpers/knowledgeBase.helper';

const Video = (props) => {
  const intl = useIntl();

  const link = useLocalizedDynamicRoute();
  const { siteUrl } = useSiteMetadata();

  const { wpVideo, allWpBanner } = props.data;
  const { relatedPosts } = wpVideo;

  const prepareRelatedPosts = (relatedPosts, currentLocale) => {
    const filtredPostList = relatedPosts.nodes.filter(post => post.locale && post.locale.locale === currentLocale);
    return filtredPostList.slice(0, 4);
  }

  const filteredPosts = prepareRelatedPosts(relatedPosts, wpVideo.locale.locale)

  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.ARTICLE)}/${wpVideo.slug}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.ARTICLE)}/${getLocalizedSlug(wpVideo,'en_US')}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.ARTICLE)}/${getLocalizedSlug(wpVideo,'pl_PL')}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.ARTICLE)}/${getLocalizedSlug(wpVideo,'de_DE')}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.ARTICLE)}/${getLocalizedSlug(wpVideo,'cs_CZ')}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.ARTICLE)}/${getLocalizedSlug(wpVideo,'fr_FR')}/`,
          },
        ]}
        canonical={siteUrl + link(PageType.KNOWLEDGE_BASE_ARTICLES, wpVideo.slug)+'/'}
        title={wpVideo.seo.title}
        description={wpVideo.seo.metaDesc}
        metaTags={[
          {
            property: 'thumbnail',
            content: ThumbnailsHelper[intl.locale],
          },
          {
            property: 'og:image',
            content: coversDictionary[intl.locale],
          },
          {
            property: 'og:title',
            content: wpVideo.seo.opengraphTitle,
          },
          {
            property: 'og:description',
            content: wpVideo.seo.opengraphDescription,
          },
          {
            property: 'twitter:title',
            content: wpVideo.seo.opengraphTitle,
          },
          {
            property: 'twitter:description',
            content: wpVideo.seo.opengraphDescription,
          },
        ]}
      />
      <VideoPost video={wpVideo} banner={allWpBanner} categoriesPosts={filteredPosts}/>
    </>
  );
};

export default Video;

export const query = graphql`
  query MyQuery($id: String!, $currentLang: String!) {
    wpVideo(id: {eq: $id}) {
      translated {
        id
        slug
        locale {
          locale
        }
      }
      slug
      id
      title
      categories {
        nodes {
          id
          slug
          name
        }
      }
      locale {
        locale
      }
      excerpt
      date
      contentTypeName
      videoContent {
        author {
          ... on WpArticleAuthor {
            id
            article_author {
              authorName
              fieldGroupName
              authorPicture {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, quality: 100)
                  }
                }
              }
            }
          }
        }
        fieldGroupName
        videoYoutube
      }
      featuredImage {
        node {
          id
          localFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      relatedPosts {
        nodes {
          id
          slug
          title
          locale {
            locale
          }
          categories {
            nodes {
              id
              slug
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          date
        }
      }
      seo {
        metaDesc
        canonical
        title
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphImage {
          id
          localFile {
            id
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
      }
    }
    allWpBanner(filter: {slug: {eq: "banner-a"}, locale: {locale: {eq: $currentLang}}}) {
      edges {
        node {
          id
          bannerProperties {
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerMobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerUrl
          }
        }
      }
    }
  }

`;
